import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.textBig}>
        It is Brand Gig's policy to respect your privacy regarding any information we may collect while operating our websites.
      </p>

      <p className={css.textBig}>
        Our policy in easy to read format:
      </p>

      <ul>
        <li>We don’t ask you for your personal data unless we truly need it. </li>
        <li>We don’t share your personal data with anyone except to comply with the law, develop our products, or protect our rights. </li>
        <li>We don’t store personal data on our servers unless required for the on-going operation of one of our services.</li>
      </ul>


      <h3>Website Visitors</h3>

      <p>
        Like most website operators, Brand Gig collects non-personally-identifying information of the sort that 
        web browsers and servers typically make available, such as the browser type, language preference, referring 
        site, and the date and time of each visitor request. Brand Gig’s purpose in collecting non-personally 
        identifying information is to better understand how Brand Gig’s visitors use its website. From time 
        to time, Brand Gig may release non-personally-identifying information in the aggregate, e.g., by 
        publishing a report on trends in the usage of its website. Brand Gig also collects potentially 
        personally-identifying information like Internet Protocol (IP) addresses for logged in users 
        and for users leaving comments on WordPress.com blogs. Brand Gig only discloses logged in user 
        and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying 
        information as described below, except that blog commenter IP addresses and email addresses are visible 
        and disclosed to the administrators of the blog where the comment was left.
      </p>

      <h3>Gathering of Personally-Identifying Information</h3>
      
      <p>
        Certain visitors to Brand Gig’s websites choose to interact with Brand Gig in ways that require Brand Gig 
        to gather personally-identifying information. The amount and type of information that Brand Gig gathers 
        depends on the nature of the interaction. In each case, Brand Gig collects such information only insofar 
        as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with Brand Gig. Brand Gig 
        does not disclose personally-identifying information other than as described below. And visitors can always 
        refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging 
        in certain website-related activities.
      </p>

      <h3>Aggregated Statistics</h3>

      <p>
        Brand Gig may collect statistics about the behavior of visitors to its websites. Brand Gig may display this 
        information publicly or provide it to others. However, Brand Gig does not disclose personally-identifying 
        information other than as described below.
      </p>

      <h3>Protection of Certain Personally-Identifying Information</h3>

      <p>
        Brand Gig discloses potentially personally-identifying and personally-identifying information only to those of its employees, 
        contractors and affiliated organizations that (i) need to know that information in order to process it on 
        Brand Gig’s behalf or to provide services available at Brand Gig’s websites, and (ii) that have agreed 
        not to disclose it to others. Some of those employees, contractors and affiliated organizations may 
        be located outside of your home country; by using Brand Gig’s websites, you consent to the transfer 
        of such information to them. Brand Gig will not rent or sell potentially personally-identifying and 
        personally-identifying information to anyone. Other than to its employees, contractors and affiliated 
        organizations, as described above, Brand Gig discloses potentially personally-identifying and 
        personally-identifying information only in response to a subpoena, court order or other governmental 
        request, or when Brand Gig believes in good faith that disclosure is reasonably necessary to protect 
        the property or rights of Brand Gig, third parties or the public at large. If you are a registered user 
        of Brand Gig website and have supplied your email address, Brand Gig may occasionally send you an email 
        to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on 
        with Brand Gig and our products. We expect to keep this type of email to a minimum. If you send us a 
        request (for example via a support email or via one of our feedback mechanisms), we reserve the right 
        to publish it in order to help us clarify or respond to your request or to help us support other users. 
        Brand Gig takes all measures reasonably necessary to protect against the unauthorized access, use, 
        alteration or destruction of potentially personally-identifying and personally-identifying information.
      </p>

      <h3>Cookies</h3>

      <p>
        A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s 
        browser provides to the website each time the visitor returns. Brand Gig uses cookies to help Brand Gig 
        identify and track visitors, their usage of Brand Gig website, and their website access preferences. 
        Brand Gig visitors who do not wish to have cookies placed on their computers should set their browsers 
        to refuse cookies before using Brand Gig’s websites, with the drawback that certain features of Brand Gig’s 
        websites may not function properly without the aid of cookies.
      </p>

      <h3>Business Transfers</h3>

      <p>
        If Brand Gig, or substantially all of its assets, were acquired, or in the unlikely event that Brand Gig 
        goes out of business or enters bankruptcy, user information would be one of the assets that is transferred 
        or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Brand Gig 
        may continue to use your personal information as set forth in this policy.
      </p>

      <h3>Ads</h3>

      <p>
        Ads appearing on any of our websites may be delivered to users by advertising partners, who may set cookies. 
        These cookies allow the ad server to recognize your computer each time they send you an online advertisement 
        to compile information about you or others who use your computer. This information allows ad networks to, 
        among other things, deliver targeted advertisements that they believe will be of most interest to you. 
        This Privacy Policy covers the use of cookies by Brand Gig and does not cover the use of cookies by any advertisers.
      </p>

      <h3>Privacy Policy Changes</h3>

      <p>
        Although most changes are likely to be minor, Brand Gig may change its Privacy Policy from time to time, and in 
        Brand Gig’s sole discretion. Brand Gig encourages visitors to frequently check this page for any changes 
        to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will 
        constitute your acceptance of such change.
      </p>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
