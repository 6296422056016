import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { tipsWithTimeZone, formatTimeWithTimeZone } from '../../util/dates';

import css from './BookingBreakdown.module.css';

const formatDay = 'ddd, MMMM DD';
const formatTime = 'h:mma';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone } = props;
  const a = moment(endDate);
  const b = moment(startDate);
  const diff = a.diff(b, 'hours');
  return (
    <div className={css.bookingDates}>
         {timeZone && <span className={css.timeZoneTip}>{tipsWithTimeZone(timeZone)}</span>}
      <div className={css.dayInfo}>
        {timeZone
          ? moment(startDate)
              .tz(timeZone)
              .format(formatDay)
          : moment(startDate).format(formatDay)}
      </div>
      <div className={css.itemLabelDate}>
        {timeZone
         ? formatTimeWithTimeZone(startDate, timeZone, formatTime)
          : moment(startDate).format(formatTime)}{' '}
        -{' '}
        {timeZone
         ? formatTimeWithTimeZone(endDate, timeZone, formatTime)
          : moment(endDate).format(formatTime)}
      <span className={css.divider}>= {diff} hours</span>
      </div>
      
    </div>
  );
};

const LineItemMultiplePeriods = props => {
  const { selectedDates, dateType, timeZone, inbox } = props;
  
  return (
    <>
      {selectedDates.map(({ startDate, endDate }) => (
        <div className={css.lineItem} key={moment(startDate).format()}>
          <BookingPeriod
            startDate={startDate}
            endDate={endDate}
            dateType={dateType}
            timeZone={timeZone}
          />         
        </div>
      ))}
      {!inbox && <hr className={css.totalDivider} />}
    </>
  );
};

export default LineItemMultiplePeriods;
