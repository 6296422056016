import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './PhoneConfirmationForm.css';

const PhoneConfirmationForm = props => {
  const { verificationError, ...rest } = props;
  
  return (
    <FinalForm
      {...rest}
      render={fieldRenderProps => {
        const {
          className,
          rootClassName,
          disabled,
          handleSubmit,
          intl,
          formId,
          invalid,
          inProgress,
          values,
          error,
          resendTimer,
          onResend          
        } = fieldRenderProps;

       

        const handleResend = e => {
          e.preventDefault();
          
          onResend();
        }
        

        const label = intl.formatMessage({ id: 'PhoneConfirmationForm.label' });
        const placeholder = intl.formatMessage({
          id: 'PhoneConfirmationForm.placeholder',
        });
        const requiredMessage = intl.formatMessage({
          id: 'PhoneConfirmationForm.requiredMessage',
        });

        const submitMessage = intl.formatMessage({
          id: 'PhoneConfirmationForm.buttonTitle',
        });

        const resendAvailable = !resendTimer;

        const resendMessage = resendAvailable ? (
          <div className={css.resendMessage}>
            <a href="#" onClick={handleResend}>
              <FormattedMessage id="PhoneConfirmationForm.resendButtonTitle"/>
            </a>
          </div>
        ): (
          <div className={css.resendMessage}>
            <FormattedMessage id="PhoneConfirmationForm.resendTimerMessage" values={{value: resendTimer}}/>
          </div>
        );
        
        const classes = classNames(rootClassName || css.root, className);
        const submitDisabled = invalid || disabled || inProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
         
            <h2 className={css.title}>
              <FormattedMessage id="PhoneConfirmationForm.title" />
            </h2>

            <FieldTextInput
              className={classNames(css.input, {[css.customErrorInputStyle]: submitDisabled})}
              type="text"
              id={formId ? `${formId}.code` : 'code'}
              name="code"
              label={label}
              placeholder={placeholder}
              validate={required(requiredMessage)}
            />

            {resendMessage}

            {values.code && verificationError && (
              <p className={css.error}>
                <FormattedMessage id="PhoneConfirmationForm.error"/>
              </p>
            )}

            {/* {errorArea} */}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={inProgress}
              disabled={submitDisabled}
            >
              {submitMessage}
            </PrimaryButton>
          </Form>
        );
      }}
    />
  )
};

export default PhoneConfirmationForm;
