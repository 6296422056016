import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldBoolean } from '../../components';


import instagram from './images/instagram.png';
import facebook from './images/facebook.png';
import twitter from './images/twitter.png';

import css from './EditListingSocialForm.module.css';

const EditListingSocialFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        certificateOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const instagramLabel = intl.formatMessage({ id: 'EditListingSocialForm.instagramLabel' });
      const instagramPlaceholderMessage = intl.formatMessage({ id: 'EditListingSocialForm.instagramPlaceholderMessage' });
      const facebookLabel = intl.formatMessage({ id: 'EditListingSocialForm.facebookLabel' });
      const facebookPlaceholderMessage = intl.formatMessage({ id: 'EditListingSocialForm.facebookPlaceholderMessage' });
      const twitterLabel = intl.formatMessage({ id: 'EditListingSocialForm.twitterLabel' });
      const twitterPlaceholderMessage = intl.formatMessage({ id: 'EditListingSocialForm.twitterPlaceholderMessage' });
     
      
      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.socialLink}>
          <FieldTextInput
            id="instagramLink"
            name="instagramLink"
            className={css.link}
            type="text"
            label={instagramLabel}
            placeholder={instagramPlaceholderMessage}
            autoFocus
          />
          <img className={css.socialImage} src={instagram} alt="instagram" />

          </div>
          <div className={css.socialLink}>          
          <FieldTextInput
            id="facebookLink"
            name="facebookLink"
            className={css.link}
            type="text"
            label={facebookLabel}
            placeholder={facebookPlaceholderMessage}            
          />
          <img className={css.socialImage} src={facebook} alt="facebook" />

          </div>
          <div className={css.socialLink}>

          <FieldTextInput
            id="twitterLink"
            name="twitterLink"
            className={css.link}
            type="text"
            label={twitterLabel}
            placeholder={twitterPlaceholderMessage}      
          />
          <img className={css.socialImage} src={twitter} alt="twitter" />

          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingSocialFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingSocialFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingSocialFormComponent);
