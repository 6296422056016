// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  if (typeof window !== 'undefined') {
    return '';
  }
  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

// export async function upload(url, data, onUploadProgress) {
//   const options = {
//     baseURL: apiBaseUrl(),
//     method: 'post',
//     headers: { "Content-Type": "multipart/form-data" },
//     url: url,
//     data,
//     onUploadProgress
//   }

//   let res = await axios(options);
//   try {
//     return res;
//   } catch (e) {
//     return new Error(e);
//   }
// }

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const request = (method, path, body, upload = false) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method,
    credentials: 'include',
  };

  if (!upload){
    options.headers = {'Content-Type': 'application/transit+json'}
  }
  if (method !== "GET") {
    options.body = upload ? body : serialize(body);
  }
  return fetch(url, options).then(async res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const post = request.bind(null, 'POST');
const put = request.bind(null, 'PUT');
const get = request.bind(null, 'GET');

const upload = async (url, files) => {
  const formData = new FormData();
  if (Array.isArray(files)){
    files.forEach(file => formData.append("files[]", file));
  } else {
    formData.append("files[]", files);
  }


  try {
    const response = await post(url, formData, true);
    return response;
  } catch(e){
    throw e;
  }
}

// const makeUpload = async (url, files, progressCallback = () => null) => {
//   const formData = new FormData();
//   files.forEach(file => formData.append("files[]", file));

//   try {
//     const response = await upload(url, formData, progressCallback);
//     return response;
//   } catch(e){
//     console.log(e);
//     return null;
//   }
// }

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const removeExceptions = body => {
  return post('/api/exceptions/remove', body);
};

export const uploadTransactionMessagesFiles = (files, {transactionId, messageId}) => {
  return upload(`/api/transactions/messages_files/upload?transactionId=${transactionId}&id=${messageId}`, files);
};

export const fetchTransactionMessagesFiles = ({transactionId}) => {
  return get(`/api/transactions/messages_files/get_all?transactionId=${transactionId}`);
};

export const fetchTransactions = params => {
  const { page, per_page } = params;
  return get(`/api/transactions?page=${page}&perPage=${per_page}`)
    .catch(e => console.log(e));
};

export const saveBookingPayInformation = (params) => {
  return post('/api/transactions/save-booking-pay-info', params)
}

export const cancelBooking = (params) => {
  return post('/api/transactions/cancel-and-refund', params)
}

export const userPhoneUpdate = params => {
  return post('/api/notifications/verification/send', params);
}

export const confirmPhone = params => {
  return post('/api/notifications/verification/check', params);
}

export const sendMessage = params => {
  return post('/api/notifications/send', params)
          .catch(e => console.log(e));
}

export const sendReminder = params => {
  return post('/api/notifications/send-reminder', params)
    .catch(e => console.log(e));
}
