import React from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './Uploader.module.css';

const ACCEPT_FILES = '.pdf,.doc,.docx,.xls,.xlsx,.png,.jpeg,.jpg';

const Uploader = props => {
  const {
    className,
    labelClassName,
    onAddFiles,
    children,
    inProgress,
    multiple = false,
    input = {},
    form,
    handleBlur
  } = props;

  const classes = classNames(css.root, className);

  const handleChange = e => {
    const files = e.target.files;

    if (!input.name && typeof onAddFiles === 'function'){
      onAddFiles(files);
    } else if (input.name && form){
      const allFiles = [...input.value, ...files];
      form.change(input.name, allFiles);
      handleBlur();
    }
  };

  const {value, onChange, ...inputProps} = input;

  const disabled = inProgress;

  return (
    <div className={classes}>
      <label className={classNames(css.uploadLabel, labelClassName)}>
        {children}

        {disabled ? null : (
          <input
            className={css.uploadInput}
            name="files"
            accept={ACCEPT_FILES}
            type="file"
            multiple={multiple}
            onChange={handleChange}
            {...(inputProps || {})}

          />
        )}
      </label>
    </div>
  )
}

export const UploaderFinalForm = props => <Field name="files" type="file" {...props} component={Uploader}/>

export default Uploader;
