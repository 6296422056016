import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getTransactions, TRANSITIONS, cancelBookingAndRefund } from '../../util/transaction';
import {
  addMarketplaceTransactionsEntities
} from '../../ducks/marketplaceData.duck';
import {denormalisedResponseEntities} from "../../util/data";

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const CANCEL_BOOKING_REQUEST = 'app/AdminPanelPage/CANCEL_BOOKING_REQUEST';
export const CANCEL_BOOKING_SUCCESS = 'app/InboxPage/CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_ERROR = 'app/InboxPage/CANCEL_BOOKING_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  cancelInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  cancelBookingError: null
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case CANCEL_BOOKING_REQUEST:
      return { ...state, cancelInProgress: true, cancelBookingError: null };

    case CANCEL_BOOKING_SUCCESS:
      return { ...state, cancelInProgress: false, cancelBookingError: null };

    case CANCEL_BOOKING_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, cancelInProgress: false, cancelBookingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const cancelBookingRequest = () => ({ type: CANCEL_BOOKING_REQUEST })
const cancelBookingSuccess = () => ({ type: CANCEL_BOOKING_SUCCESS })
const cancelBookingError = e => ({
  type: CANCEL_BOOKING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const cancelBooking = (params) => (dispatch, getState, sdk) => {
  dispatch(cancelBookingRequest());
  cancelBookingAndRefund(params)
    .then(() => {
      dispatch(loadData());
      dispatch(cancelBookingSuccess())
    })
    .catch(e => {
      dispatch(cancelBookingError(e));
      throw e;
    })
  return null;
}

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());
  const { page = 1 } = parse(search);

  return getTransactions({
        page,
        per_page: INBOX_PAGE_SIZE,
      })
    .then(response => {
      const result = denormalisedResponseEntities(response);
      dispatch(addMarketplaceTransactionsEntities({data: {data: result}}));
      dispatch(fetchOrdersOrSalesSuccess({
        data: {
          data: result,
          meta: response.data.meta
        }}
      ));
      return {data: {data: result}};
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
