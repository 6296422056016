import pick from 'lodash/pick';
import { denormalisedResponseEntities } from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { getUnavailableDates } from '../../ducks/cart.duck';

// ================ Action types ================ //
export const CHECK_UNAVAILABLE_DATES_REQUEST = 'app/CartPage/CHECK_UNAVAILABLE_DATES_REQUEST';
export const CHECK_UNAVAILABLE_DATES_COMPLETED = 'app/CartPage/CHECK_UNAVAILABLE_DATES_COMPLETED';

export const FETCH_LISTINGS_SUCCESS = 'app/CartPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/CartPage/FETCH_LISTINGS_ERROR';

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

// ================ Reducer ================ //

const initialState = {
  listings: null,
  fetchListingsInprogress: true,
  fetchListingsError: null,

  unavailableDates: null,
  unavailableDatesInProgress: false
};

export default function cartPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_LISTINGS_SUCCESS:
      return { ...state, fetchListingsInprogress: false, listings: payload };
    case FETCH_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, fetchListingsInprogress: false, fetchListingsError: payload };

    case CHECK_UNAVAILABLE_DATES_REQUEST:
      return { ...state, unavailableDatesInProgress: true, unavailableDates: null };
    case CHECK_UNAVAILABLE_DATES_COMPLETED:
      return { ...state, unavailableDatesInProgress: false, unavailableDates: payload };
  
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchListingsSuccess = payload => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload,
});

const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const checkUnavailableDatesRequest = () => ({
  type: CHECK_UNAVAILABLE_DATES_REQUEST
});

const checkUnavailableDatesCompleted = (payload = null) => ({
  type: CHECK_UNAVAILABLE_DATES_COMPLETED,
  payload,
});

export const fetchListings = () => (dispatch, getState, sdk) => {
  const { cart } = getState().cart;

  if (!cart){
    dispatch(fetchListingsSuccess(null));
    return;
  }

  const ids = Object.keys(cart);

  const params = {
    ids,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const listings = denormalisedResponseEntities(response);
      dispatch(fetchListingsSuccess(listings));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};

export const checkUnavailableDates = dates => async (dispatch, getState, sdk) => {
  dispatch(checkUnavailableDatesRequest());

  try {
    const unavailableDates = await dispatch(getUnavailableDates(dates));
    dispatch(checkUnavailableDatesCompleted(unavailableDates || null));
    return !unavailableDates;
  } catch(e){
    console.log(e)
    dispatch(checkUnavailableDatesCompleted());
  }
}