import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import losAngeles from './images/los-angeles.png';
import dallas from './images/dallas.png';
import newYork from './images/new-york.png';


class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.subTitle}>
        <FormattedMessage id="SectionLocations.subTitle" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Los Angeles',
          losAngeles,
          '?address=Los%20Angeles%2C%20CA%2C%20USA&bounds=34.33730608759191%2C-118.155289077463%2C33.70365193147634%2C-118.6681759484859'
        )}
        {locationLink(
          'Dallas',
          dallas,
          '?address=Dallas%2C%20TX%2C%20USA&bounds=33.0237920649461%2C-96.46375805411307%2C32.6175369971454%2C-96.9993469629029'
        )}
        {locationLink(
          'New York',
          newYork,
          '?address=New%20York%2C%20NY%2C%20USA&bounds=40.91757705070789%2C-73.70027206817629%2C40.47739906045452%2C-74.25908991427882'
        )}
      </div>

      <NamedLink
        name="SearchPage"
        className={css.button}
      >
        <FormattedMessage id="SectionLocations.seeMore" />
      </NamedLink>

      <div className={css.divider} />
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
