/**
 * Slice a local number that is in the form
 * of 555 01234567
 */

const sliceLocal = numbers => {
  if (numbers.length <= 3) {
    return numbers;
  }
  return `${numbers.slice(0, 3)} ${numbers.slice(3, 10)}`;
};

/**
 * Slice an international phone number i.e.
 * the part that is followed after a '+' or '00'.
 */
const sliceInternational = numbers => {
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 6) {
    return `${numbers.slice(0, 3)} ${numbers.slice(3)}`;
  }
  return `${numbers.slice(0, 3)} ${numbers.slice(3, 6)} ${numbers.slice(6, 12)}`;
};

const sliceUSA = numbers => {
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 6) {
    return `${numbers.slice(0, 3)} ${numbers.slice(3, 6)}`;
  }
  return `${numbers.slice(0, 3)} ${numbers.slice(3, 6)} ${numbers.slice(6, 10)}`;
};

/**
 * Format a phone number in a Finnish
 * mobile phone number format.
 *
 * Uses one of the following formats:
 *
 * +123 55 1234567
 * 00123 55 1234567
 * 555 1234567
 */
 export const formatUSA = (value = '') => {
  const number = /^\+/.test(value) ? value.slice(1) : value;
  return number.replace(/[^\d]/g, '');
};

export const format = value => formatUSA(value);

/**
 * Parser that strips whitespaces away from a phone number
 * string so that the plain number can be stored.
 */
const parseRegular = value => (value ? value.replace(/\s/g, '') : '');

const parseUSA = value => {
  const numbers = (/^\+/.test(value) ? value.slice(1) : value).replace(/[^\d]/g, '');
  if (!numbers) {
    return '';
  }

  return `+${numbers}`;
};

export const parse = value => parseUSA(value);