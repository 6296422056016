import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate, monthIdStringInTimeZone, nextMonthFn } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
// import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import MultiDatesForm from './MultiDatesForm';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import arrayMutators from 'final-form-arrays';
import debounce from 'lodash/debounce';

import css from './BookingTimeForm.module.css';

const formatDate = 'ddd, MMM DD';
const formatTime = 'hh:mma';

const initialDates = {
  bookingStartDate: { date: null },
  bookingEndDate: { date: null },
  bookingStartTime: null,
  bookingEndTime: null,
};

const multiFieldName = 'multiDates';

const isValidBookingData = bookingData =>
  bookingData &&
  bookingData.every(({ startDate, endDate }) => startDate && endDate && startDate !== endDate);

const prepareMulti = fields => {
  return fields
    .map(({ bookingStartTime, bookingEndTime }) => ({
      startDate: bookingStartTime ? timestampToDate(bookingStartTime) : null,
      endDate: bookingEndTime ? timestampToDate(bookingEndTime) : null,
    }))
    .sort((a, b) => {
      if (!a.startDate) {
        return 1;
      }
      return !moment(a.startDate).isSame(moment(b.startDate))
        ? moment(a.startDate).isAfter(moment(b.startDate))
          ? 1
          : -1
        : 0;
    });
};

const preselectedIsChanged = (prev, current) => {
  return (
    (!prev && current) ||
    (!current && prev) ||
    prev.length !== current.length ||
    current.some(({ startDate, endDate }, i) => {
      return (
        +moment(startDate) !== +moment(prev[i].startDate) ||
        +moment(endDate) !== +moment(prev[i].endDate)
      );
    })
  );
};

const sortBookings = bookings =>
  bookings.sort((a, b) => {
    return !moment(a.startDate).isSame(moment(b.startDate))
      ? moment(a.startDate).isAfter(moment(b.startDate))
        ? 1
        : -1
      : 0;
  });

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preselectedDates: null,
      justBookNow: false,
      initialValues: {
        [multiFieldName]: [initialDates],
      },
      fetchAdditionalSlotsInProgress: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = debounce(this.handleOnChange.bind(this), 150);
  }

  hasValidBookings(initialBookings) {
    return initialBookings.some(({ unavailable }) => !unavailable);
  }

  componentDidMount() {
    const { initialBookings } = this.props;
    if (initialBookings && this.hasValidBookings(initialBookings)) {
      this.setState({ initialValues: this.setInitialValues() });
    }
  }

  componentDidUpdate(prevProps) {
    const { initialBookings, monthlyTimeSlots } = this.props;
    const { fetchAdditionalSlotsInProgress } = this.state;
    if (prevProps.initialBookings !== initialBookings && this.hasValidBookings(initialBookings)) {
      this.setState({ initialValues: this.setInitialValues() });
    }

    if (
      initialBookings &&
      monthlyTimeSlots &&
      Object.values(monthlyTimeSlots).every(s => s && !s.fetchTimeSlotsInProgress) &&
      !fetchAdditionalSlotsInProgress
    ) {
      const sortedInitialBookings = sortBookings(initialBookings);
      const lastSlotEndTime = sortedInitialBookings[sortedInitialBookings.length - 1].endDate;

      //try to fetch unfetched time slots
      this.fetchAdditionalTimeSlotsMaybe(lastSlotEndTime);
    }
  }

  readyToBookNow(values) {
    const selectedDates = values[multiFieldName];
    return !selectedDates || selectedDates.length <= 1;
  }

  handleFormSubmit(e) {
    const { justBookNow } = this.state;

    const { onSubmit } = this.props;
    const values = e[multiFieldName];

    const unavailableDates = this.getUnavailable();

    this.setState({ justBookNow: false }, () => {
      onSubmit(
        [...values, ...(unavailableDates ? this.createValuesFromBookings(unavailableDates) : [])],
        !justBookNow
      );
    });
  }

  handleChangeMulti = formValues => {
    const { preselectedDates } = this.state;
    const fields = formValues.values[multiFieldName];
    const bookingData = prepareMulti(fields);

    if (!preselectedIsChanged(preselectedDates, bookingData)) {
      return;
    }

    this.setState({ preselectedDates: bookingData }, () => {
      const allDatesCorrect = !!isValidBookingData(bookingData);
      if (allDatesCorrect && !this.props.fetchLineItemsInProgress) {
        const listingId = this.props.listingId;
        const isOwnListing = this.props.isOwnListing;

        this.props.onFetchTransactionLineItems({
          bookingData,
          listingId,
          isOwnListing,
        });
      }
    });
  };

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.

  handleOnChange(formValues) {
    if (formValues.values && formValues.values[multiFieldName]) {
      return this.handleChangeMulti(formValues);
    }

    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
      });
    }
  }

  handleSubmitClick(form, justBookNow) {
    this.setState({ justBookNow }, () => {
      setTimeout(() => {
        form.submit();
      }, 0);
    });
  }

  fetchAdditionalTimeSlotsMaybe(time) {
    const { onFetchTimeSlots, monthlyTimeSlots, timeZone } = this.props;

    if (!timeZone) {
      return;
    }
    const monthId = monthIdStringInTimeZone(time, timeZone);

    if (!monthlyTimeSlots || !Object.keys(monthlyTimeSlots).length || monthlyTimeSlots[monthId]) {
      return;
    }

    const [lastFetchedYear, lastFetchedMonth] = Object.keys(monthlyTimeSlots)
      .find((key, i, arr) => arr.every(k => k <= key))
      .split('-');

    const lastFetchedDate = moment(`${lastFetchedMonth}-01-${lastFetchedYear}`).tz(timeZone);
    const lastDate = moment(time)
      .tz(timeZone)
      .startOf('month')
      .add(1, 'months');
    const monthsDiff = lastDate.diff(lastFetchedDate, 'months');

    const listingId = listing.id.uuid;

    this.setState({ fetchAdditionalSlotsInProgress: true }, async () => {
      for (let i = 1; i < monthsDiff; i++) {
        const start = lastFetchedDate
          .clone()
          .tz(timeZone)
          .add(i, 'months')
          .toDate();
        const end = nextMonthFn(start, timeZone);

        try {
          await onFetchTimeSlots(listingId, start, end, timeZone);
        } catch (e) {
          //
          console.log(e);
        }
      }

      this.setState({ fetchAdditionalSlotsInProgress: false });
    });
  }

  createValuesFromBookings(bookings) {
    return bookings.map(({ startDate, endDate }) => ({
      bookingStartDate: { date: moment(startDate).toDate() },
      bookingEndDate: { date: moment().toDate() },
      bookingStartTime: +moment(startDate),
      bookingEndTime: +moment(endDate),
    }));
  }

  setInitialValues() {
    const { initialBookings } = this.props;

    if (!initialBookings) {
      return null;
    }

    const sortedAvailableBookings = sortBookings(initialBookings).filter(
      ({ unavailable }) => !unavailable
    );

    return {
      [multiFieldName]: sortedAvailableBookings.length
        ? this.createValuesFromBookings(sortedAvailableBookings)
        : null,
    };
  }

  getUnavailable() {
    const { initialBookings } = this.props;

    if (!initialBookings) {
      return null;
    }

    return sortBookings(initialBookings).filter(({ unavailable }) => !!unavailable);
  }

  renderUnavailableDates() {
    const { onRemoveFromCart, timeZone } = this.props;

    const sortedUnavailableBookings = this.getUnavailable();

    if (!sortedUnavailableBookings || !sortedUnavailableBookings.length) {
      return null;
    }

    return (
      <div className={css.unavailableDates}>
        <h3 className={css.unavailableDatesTitle}>
          <FormattedMessage id="BookingTimeForm.unavailableDatesTitle" />
        </h3>

        {sortedUnavailableBookings.map(dates => {
          const { startDate, endDate } = dates;

          const removeItem = e => {
            e.preventDefault();

            onRemoveFromCart(this.createValuesFromBookings([dates]));
          };

          return (
            <div className={css.unuavailableItem}>
              <div className={css.unuavailableItemDate}>
                <div className={css.unuavailableItemDay}>
                  {timeZone
                    ? moment(startDate)
                        .tz(timeZone)
                        .format(formatDate)
                    : moment(startDate).format(formatDate)}
                </div>
                <div className={css.unuavailableItemTime}>
                  {timeZone
                    ? moment(startDate)
                        .tz(timeZone)
                        .format(formatTime)
                    : moment(startDate).format(formatTime)}{' '}
                  -{' '}
                  {timeZone
                    ? moment(endDate)
                        .tz(timeZone)
                        .format(formatTime)
                    : moment(endDate).format(formatTime)}
                </div>
              </div>

              <a hraf="#" onClick={e => removeItem(e)} className={css.unavailableDatesRemove}>
                x
              </a>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      cartFetched,
      rootClassName,
      className,
      price: unitPrice,
      initialBookings,
      ...rest
    } = this.props;
    const { initialValues, preselectedDates } = this.state;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            listing,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            onRemoveFromCart,
            cartLoaded,
          } = fieldRenderProps;


          // const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          // const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          // const startDate = startTime ? timestampToDate(startTime) : null;
          // const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData = isValidBookingData(preselectedDates)
            ? {
                unitType,
                bookingDates: preselectedDates,
                timeZone,
              }
            : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const showBookNow = this.readyToBookNow(values);

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              {this.renderUnavailableDates()}

              {monthlyTimeSlots && timeZone ? (
                <MultiDatesForm
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  listing={listing}
                  name={multiFieldName}
                  initialDates={initialDates}
                  selectedDates={preselectedDates}
                  onRemoveFromCart={onRemoveFromCart}
                  initialBookings={initialBookings}
                  cartLoaded={cartLoaded}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {showBookNow && (
                <div className={css.bookNowButtonWrapper}>
                  <PrimaryButton type="button" onClick={() => this.handleSubmitClick(form, true)}>
                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                  </PrimaryButton>
                </div>
              )}
              {cartLoaded && (
                <div className={submitButtonClasses}>
                  <PrimaryButton type="button" onClick={() => this.handleSubmitClick(form, false)}>
                    <FormattedMessage id="BookingTimeForm.addToQueue" />
                  </PrimaryButton>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
