import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { timestampToDate } from '../../util/dates';
import { createInputRefId } from '../../util/urlHelpers';
import moment from 'moment';

import css from './MultiDatesForm.module.css';

const findOverlaps = (slots, start, end) => {
  return slots.filter(({startDate, endDate}) => {
    return moment(start).isSameOrBefore(moment(startDate)) && moment(end).isSameOrAfter(moment(endDate));
  })
};

const createCustomSlot = (ref, start, end) => {
  return {
    ...ref,
    id: {uuid: 'custom'},
    attributes: {
      ...ref.attributes,
      start,
      end
    }
  }
};

const tryToBreakSlot = (slot, selectedSlots) => {
  const {start, end} = slot.attributes;
  const overlaps = findOverlaps(selectedSlots, start, end);

  if (!overlaps.length){
    return [slot];
  }

  return overlaps.reduce((result, overlap, index, arr) => {
    const isFirst = !index;
    const isLast = index == arr.length - 1;
    const {startDate, endDate} = overlap;

    if (moment(start).isSame(moment(startDate)) && moment(end).isSame(moment(endDate))){
      return result;
    }

    const firstSlot = isFirst && !moment(start).isSame(moment(startDate)) ? [createCustomSlot(slot, start, startDate)]: [];
    const lastSlot = isLast && !moment(end).isSame(moment(endDate)) ? [createCustomSlot(slot, endDate, end)]: [];

    const slotMaybe = !isFirst && !(moment(startDate).isSame(moment(arr[index-1].endDate))) ?
                      [createCustomSlot(slot, arr[index-1].endDate, startDate)] :
                      [];

    return [
      ...result,
      ...firstSlot,
      ...slotMaybe,
      ...lastSlot,
    ];
  }, [])
}

const manageTimeslots = (slots, selectedDates, values, minHours) => {
  if (!slots){
    return null;
  }


  // try to remove all selected dates ranges from slots except current
  // and slots that shorter then min duration
  // it is necessary for end times rendering
  return {
    ...Object.entries(slots).reduce(
      (obj,[key, item]) => {
        const {timeSlots = []} = item;

      const selectedDatesWithoutCurrent = selectedDates && selectedDates.filter(({startDate}) => {
        return startDate && !moment(startDate).isSame(moment(timestampToDate(values.bookingStartTime)))
      });

      return {...obj,
        [key]: {
          ...item,
          timeSlots: timeSlots.reduce((slots, slot) => {
            return [
              ...slots,
              ...(selectedDatesWithoutCurrent && selectedDatesWithoutCurrent.length ?
                    tryToBreakSlot(slot, selectedDatesWithoutCurrent) :
                    [slot]
              )
            ]
          }, [])
          .filter(i => {
            const start = moment(i.attributes.start);
            const end = moment(i.attributes.end);
            return end.diff(start, 'hours') >= minHours;
          })
        },
      };
    }, {})
  };
}

const MultiDatesForm = props => {
  const {
    rootClassName,
    name,
    form,
    values,
    intl,
    initialDates,
    monthlyTimeSlots,
    selectedDates,
    listing,
    onRemoveFromCart,
    cartLoaded,
    ...rest
  } = props;

  const minHours =
    listing &&
    listing.attributes &&
    listing.attributes.publicData &&
    listing.attributes.publicData.minHours;

  const classes = classNames(css.root, rootClassName);

  const buttonAddTitle = intl.formatMessage({id: 'MultiDatesForm.buttonAddTitle'});

  const handleAdd = (e, fields) => {
    e.preventDefault();
    e.stopPropagation();
    fields.push({...initialDates})
  }

  return (
    <div className={classes}>
      <FieldArray name={name}>
        {({ fields }) => {
          return (
            <>
              <div className={css.formContainer}>
                {fields.map((fieldsName, index) => {
                  const values = fields.value[index];
                  const hasValidValues = values && values.bookingStartTime && values.bookingEndTime;

                  const canBeRemoved = fields.length > 1;

                  const removeItem = e => {
                    e.preventDefault();
                    fields.remove(index);

                    if (hasValidValues){
                      onRemoveFromCart([values])
                    }
                  }

                  return (
                    <div
                      key={fieldsName}
                      className={css.datesInputContainer}
                      id={createInputRefId(index)}
                    >
                      {canBeRemoved && (
                        <a
                          hraf="#"
                          onClick={removeItem}
                          className={css.removeButton}
                        >
                          x
                        </a>
                      )}

                      <FieldDateAndTimeInput
                        {...rest}
                        listing={listing}
                        intl={intl}
                        formId={`form-${fieldsName}`}
                        fieldName={fieldsName}
                        values={values}
                        form={form}
                        multi
                        monthlyTimeSlots={manageTimeslots(monthlyTimeSlots, selectedDates, values, minHours)}
                      />
                    </div>
                  )
                })}
              </div>
              {cartLoaded &&
              <div className={css.buttonAddContainer}>
                <button
                  className={css.buttonAdd}
                  onClick={e => handleAdd(e, fields)}
                >
                  {buttonAddTitle}
                </button>
              </div>
        }
            </>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default MultiDatesForm;
