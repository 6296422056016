import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      fill="none"
      viewBox="0 0 36 35"
      className={classes}
    >
      <path      
        d="M18.25 2.917c3.963 0 4.457.015 6.012.087 1.553.073 2.61.317 3.54.679a7.11 7.11 0 012.585 1.681 7.158 7.158 0 011.681 2.584c.36.93.605 1.988.678 3.541.069 1.555.088 2.049.088 6.011 0 3.963-.015 4.457-.088 6.012-.073 1.553-.318 2.61-.678 3.54a7.122 7.122 0 01-1.681 2.585 7.167 7.167 0 01-2.585 1.681c-.929.36-1.987.605-3.54.678-1.555.069-2.05.088-6.012.088-3.962 0-4.456-.015-6.01-.088-1.554-.073-2.611-.318-3.542-.678a7.13 7.13 0 01-2.584-1.681 7.152 7.152 0 01-1.681-2.585c-.362-.929-.606-1.987-.679-3.54-.068-1.555-.087-2.05-.087-6.012 0-3.962.015-4.456.087-6.01.073-1.556.317-2.611.679-3.542a7.117 7.117 0 011.681-2.584 7.141 7.141 0 012.584-1.681c.93-.362 1.986-.606 3.541-.679 1.555-.068 2.049-.087 6.011-.087zm0 7.292a7.292 7.292 0 100 14.584 7.292 7.292 0 000-14.584zm9.48-.365a1.823 1.823 0 10-3.647 0 1.823 1.823 0 003.647 0zm-9.48 3.281a4.375 4.375 0 110 8.751 4.375 4.375 0 010-8.75z"
      ></path>
    </svg>
  );
}
IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
