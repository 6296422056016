import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal } from '../../components';
import { PhoneConfirmationForm } from '../../forms';
import { resendCode, sendVerificationPhone, cancelVerificationPhone } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import moment from 'moment';

import css from './PhoneConfirmationModal.css';

const RESEND_LIMIT = 60; //seconds
const storageKey = 'verificationResendBlockedUntil';

const createResendUntil = value => {
  if (typeof window === 'undefined') {
    return;
  }

  window.localStorage.setItem(
    storageKey,
    moment()
      .add(value, 'seconds')
      .format()
  );
};

const getResendUntil = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const stored = window.localStorage.getItem(storageKey);
  const diff = stored ? moment(stored).diff(moment(), 'seconds') : 0;

  if (diff <= 0) {
    window.localStorage.removeItem(storageKey);
    return 0;
  }

  return diff;
};

class PhoneConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'hidden',
      resendTimer: getResendUntil(),
    };

    this.timer = null; 
  }

  // componentDidMount() {
   
  // }

  componentWillUnmount() {
    this.stopTimer();
  }

  componentDidUpdate(prevProps) {
    const { phoneVerificationRequested } = this.props;
    if (prevProps.phoneVerificationRequested !== phoneVerificationRequested) {
      this.setState({ key: phoneVerificationRequested ? 'show' : 'hidden' });
    }

    if (!prevProps.phoneVerificationRequested && !!phoneVerificationRequested && !this.timer) {
      this.startTimer();
    }
  
  }

  startTimer() {
    const timeLeft = getResendUntil();
    this.handleTimer(timeLeft);

    this.timer = setTimeout(() => {
      this.props.onCancelVerificationPhone();
      clearInterval(this.timer);
      this.timer = null;
    }, RESEND_LIMIT*1000);
  }

  stopTimer() {
    if (!this.timer) {
      return;
    }
    clearInterval(this.timer);
    this.timer = null;
  }

  handleTimer(initTime) {
    this.setState({ resendTimer: initTime || RESEND_LIMIT }, () => {
      if (!initTime) {
        createResendUntil(RESEND_LIMIT);
      }
      this.timer = setInterval(() => {
        const { resendTimer } = this.state;
        if (resendTimer <= 0) {
          this.stopTimer();
          return;
        }
        this.setState({ resendTimer: resendTimer - 1 });
      }, 1000);
    });
  }

  render() {
    const {
      phoneVerificationRequest,
      phoneVerificationRequested,
      phoneVerificationError,
      onSendVerificationPhone,
      onCancelVerificationPhone,
      onManageDisableScrolling,
      onResendCode,
      intl,
    } = this.props;

    const { resendTimer, key } = this.state;

    const handleSubmit = ({ code }) => {
      onSendVerificationPhone(code);
    };

    const handleResend = async () => {
      if (resendTimer) {
        return;
      }
      await onResendCode();
      this.handleTimer();
    };

    return (
      <Modal
        id={'PhoneVerificationModal'}
        isOpen={phoneVerificationRequested}
        onClose={onCancelVerificationPhone}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonHide
        usePortal
      >
        <PhoneConfirmationForm
          key={key}
          onSubmit={handleSubmit}
          onResend={handleResend}
          inProgress={phoneVerificationRequest}
          verificationError={phoneVerificationError}
          intl={intl}
          resendTimer={resendTimer}
        />
      </Modal>
    );
  }
}

const { bool, string } = PropTypes;

PhoneConfirmationModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

PhoneConfirmationModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
};

const mapStateToProps = state => {
  const {
    phoneVerificationRequest,
    phoneVerificationRequested,
    phoneVerificationError,
  } = state.user;

  return {
    phoneVerificationRequest,
    phoneVerificationRequested,
    phoneVerificationError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendVerificationPhone: data => dispatch(sendVerificationPhone(data)),
  onCancelVerificationPhone: () => dispatch(cancelVerificationPhone()),
  onResendCode: () => dispatch(resendCode()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PhoneConfirmationModal);
