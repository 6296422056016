import React from 'react';
import classNames from 'classnames';

import css from './IconFile.module.css';

const IconFile = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path        
        d="M1.59 9.692c-.02 0-.042 0-.063-.002a1.585 1.585 0 01-1.055-.47 1.586 1.586 0 01-.47-1.055c-.017-.4.124-.77.397-1.043L6.703.818A1.758 1.758 0 018.036.31c.505.021.986.237 1.356.606.37.37.585.852.606 1.356.022.512-.159.985-.507 1.334l-4.75 4.75a.227.227 0 01-.322-.32l4.75-4.75c.259-.259.392-.612.376-.995a1.586 1.586 0 00-.474-1.054A1.586 1.586 0 008.018.764a1.308 1.308 0 00-.995.375L.72 7.443c-.38.38-.347 1.034.074 1.455.207.207.474.328.753.34a.928.928 0 00.703-.266l5.125-5.125c.225-.225.203-.612-.049-.864a.68.68 0 00-.451-.203.542.542 0 00-.412.154L2.89 6.506a.227.227 0 01-.321-.32l3.572-3.573a.988.988 0 01.752-.287c.28.012.548.131.753.337.429.428.45 1.103.05 1.505L2.57 9.293a1.369 1.369 0 01-.98.399z"
      ></path>
    </svg>
  );
};

export default IconFile;
