import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldBoolean } from '../../components';

import css from './EditListingExperienceForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingExperienceFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        certificateOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const numberMessage = intl.formatMessage({ id: 'EditListingExperienceForm.numberMessage' });
      const numberPlaceholderMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.numberPlaceholder',
      });

      const actingExperience = intl.formatMessage({
        id: 'EditListingExperienceForm.actingExperience',
      });
      const bartendingExperience = intl.formatMessage({
        id: 'EditListingExperienceForm.bartendingExperience',
      });

      const yesOption = intl.formatMessage({
        id: 'EditListingExperienceForm.yes',
      });
      const noOption = intl.formatMessage({
        id: 'EditListingExperienceForm.no',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.showListingFailed" />
        </p>
      ) : null;

      const EXP_MAX_LENGTH = 3;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const maxLengthMessage = intl.formatMessage({ id: 'EditListingExperienceForm.maxLength'});

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="experienceNumber"
            name="experienceNumber"
            className={css.title}
            type="number"
            label={numberMessage}
            placeholder={numberPlaceholderMessage}
            autoFocus
            min={0}
            max={100}
            validate={maxLength60Message}
          />

          <FieldBoolean
            id="isActingExperience"
            name="isActingExperience"
            placeholder="Choose yes/no"
            label={actingExperience}
            className={css.boolean}
          />

          <FieldBoolean
            id="isBartendingExperience"
            name="isBartendingExperience"
            label={bartendingExperience}
            placeholder="Choose yes/no"
            className={css.boolean}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingExperienceFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingExperienceFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingExperienceFormComponent);
