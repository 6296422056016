import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { IconEditPencil, NamedLink, ResponsiveImage, AvatarMedium } from '..';
import { types as sdkTypes } from '../../util/sdkLoader';
import { calculateQuantityFromHours } from '../../util/dates';
import config from '../../config';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { createSlug } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import css from './CartCard.module.css';
import { tipsWithTimeZone, formatTimeWithTimeZone } from '../../util/dates';

const { Money } = sdkTypes;

const formatDate = 'ddd, MMMM DD';
const formatTime = 'hh:mma';

const CheckedSvg = (
  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.63662 7.78248L0.357369 5.64479C-0.119123 5.17089 -0.119123 4.40293 0.357369 3.9266C0.835083 3.4527 1.60847 3.4527 2.08618 3.9266L3.34647 5.05208L6.17217 0.505847C6.56558 -0.0373165 7.32674 -0.16369 7.87776 0.227582C8.42512 0.618854 8.55096 1.37831 8.15755 1.92269L4.4925 7.6318C4.27258 7.93801 3.91215 8.13851 3.50042 8.13851C3.17787 8.13851 2.86754 8.01214 2.63662 7.78248Z" fill="white"/>
  </svg>
)

const Checkbox = ({checked}) => (
  <div className={classNames(css.checkboxRoot, {[css.checkboxChecked]: checked})}>
    {CheckedSvg}
  </div>
);

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const createBookingPrice = (price, duration) => {
  const {amount, currency} = price;
  return new Money(amount * duration, currency);

}

const ListingImage = props => <ResponsiveImage {...props} />;
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const CartCard = props => {
  const {
    className,
    listing,
    intl,
    booking,
    onRemove,
    refId,
    handleChecked,
    checked = true,
    unavailable = false,
    checkbox = false
  } = props;

  if (!listing || !listing.id) {
    return null;
  }

  const { startDate, endDate } = booking;

  const id = listing.id.uuid;
  const author = listing.author;
  const { title = '', price, publicData } = listing.attributes;
  const slug = createSlug(title);
  const firstImage =
    listing.images && listing.images.length > 0 ? listing.images[0] : null;

  const timeZone = listing.attributes && listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

  const { formattedPrice } = priceData(price, intl);

  const classes = classNames(css.root, className, {[css.checked]: checked});

  const duration = calculateQuantityFromHours(startDate, endDate);

  const bookingPrice = createBookingPrice(price, duration);

  const { formattedPrice: formattedBookingPrice } = priceData(bookingPrice, intl);

  return (
    <div className={classes}>
      <div className={css.container}>
        {checkbox && (
          <div
            className={css.checkbox}
            onClick={handleChecked}
          >
            {!unavailable && <Checkbox checked={checked}/>}
          </div>
        )}
        <div className={css.imageWrapper}>
          <div className={css.imageContainer}>
            <NamedLink className={css.imageInner} name="ListingPage" params={{ id, slug }}>
              <LazyImage
                rootClassName={css.image}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </NamedLink>
          </div>

          <div className={css.sectionAvatar}>
            <AvatarMedium user={author} className={css.avatar} disableProfileLink />
          </div>
        </div>

        <div className={css.cardContent}>
          {unavailable && (
            <div className={css.unavailable}>
              <FormattedMessage id="CartCard.unavailableTitle"/>
            </div>
          )}
          <div className={css.cardContentTop}>
            <div className={css.listingInfo}>
              <h3 className={css.title}>{title}</h3>

              <div className={css.text}>
                <FormattedMessage id="CartCard.perHour" values={{price: formattedPrice}}/>
              </div>
            </div>

            <div className={css.bookingDates}>
            {timeZone && <span className={css.timeZoneTip}>{tipsWithTimeZone(timeZone)}</span>} 
              <div className={css.text}>
                {timeZone ? 
                 moment(startDate).tz(timeZone).format(formatDate) :
                 moment(startDate).format(formatDate)}
              </div>
              <div className={classNames(css.text, css.textRight)}>                                           
                {timeZone ?
                  formatTimeWithTimeZone(startDate, timeZone, formatTime) :
                  moment(startDate).format(formatTime)
                } - {
                  timeZone ?
                  formatTimeWithTimeZone(endDate, timeZone, formatTime) :
                  moment(endDate).format(formatTime)
                }
              </div>
              <div className={classNames(css.text, css.textRight, css.textBold)}>
                <FormattedMessage id="CartCard.duration" values={{duration}}/>
              </div>
            </div>

            <div className={css.bookingPrice}>
              {formattedBookingPrice}
            </div>
          </div>
          

          <div className={css.cardContentBottom}>
            <button
              className={css.controlButton}
              onClick={onRemove}
            >
              X <FormattedMessage id="CartCard.buttonRemoveTitle"/>
            </button>

            {!unavailable && (
              <NamedLink className={css.controlButton} name="ListingPage" params={{ id, slug }} to={{search: '?book=true'}}>
                <IconEditPencil/>
                <FormattedMessage id="CartCard.buttonEditTitle"/>
              </NamedLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(CartCard);