import React from 'react';
import classNames from 'classnames';

import css from './IconAttachament.module.css';

const IconAttachament = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2923_9996)">
        <path d="M2.38547 14.5376C2.35403 14.5376 2.32236 14.5369 2.29064 14.5356C1.70175 14.5111 1.13993 14.2602 0.708707 13.8289C0.27748 13.3977 0.0265202 12.8359 0.00197324 12.247C-0.0229367 11.6475 0.188684 11.092 0.59786 10.6828L10.0538 1.22679C10.5767 0.703949 11.2878 0.433525 12.0546 0.465422C12.8112 0.496866 13.5335 0.819787 14.0883 1.37457C14.6431 1.92944 14.9661 2.65169 14.9975 3.40833C15.0294 4.17564 14.759 4.88619 14.2361 5.40911L7.11046 12.5348C6.97761 12.6676 6.76218 12.6677 6.62919 12.5348C6.49634 12.4019 6.49634 12.1864 6.62919 12.0535L13.7548 4.92775C14.1415 4.54108 14.3414 4.01148 14.3175 3.43656C14.2931 2.85097 14.0408 2.28956 13.607 1.85575C13.1733 1.42198 12.6119 1.16975 12.0263 1.14539C11.4514 1.1212 10.9217 1.32125 10.5351 1.70797L1.07913 11.164C0.507613 11.7355 0.557432 12.7151 1.18998 13.3476C1.50015 13.6578 1.90107 13.8382 2.31891 13.8556C2.72614 13.8714 3.10065 13.7315 3.37357 13.4585L11.0617 5.77047C11.3984 5.43371 11.3652 4.85261 10.9877 4.4751C10.8011 4.28844 10.5606 4.17995 10.3106 4.16961C10.0706 4.15999 9.85164 4.24198 9.69238 4.40128L4.33475 9.7591C4.20181 9.8919 3.98638 9.89195 3.85348 9.7591C3.72054 9.62615 3.72054 9.41072 3.85348 9.27782L9.21111 3.92001C9.50658 3.62454 9.90632 3.47081 10.3389 3.4896C10.76 3.50707 11.1613 3.68615 11.4689 3.99388C12.1119 4.63668 12.145 5.64955 11.5429 6.25174L3.85484 13.9398C3.46731 14.3273 2.94856 14.5376 2.38547 14.5376Z" fill="#4D6479"/>
      </g>
    </svg>
  );
};

export default IconAttachament;
