import React, { useState, useRef, useEffect } from 'react';
import { func, instanceOf, object, node, string, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import moment from 'moment';

import { ValidationError } from '../../components';

import css from './FieldAntDatePickerInput.module.css';

function FieldAntDatePickerInputComponent({
  defaultValue,
  id,
  className,
  rootClassName,
  label,
  placeholder,
  format,
  validate,
  form,
  meta,
  customErrorText,
  input: {
    value,
    name
  }
}) {

  const [touched, setTouched] = useState(false);
  
  const { valid, invalid, error } = meta;

  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };


  const dateFormat = 'DD/MM/YYYY';
  const classes = classNames(rootClassName || css.fieldRoot, className);

  const getInitialValue = () => {
    return value ? moment(value) : null;
  };
  
  const handleChange = (date)  => {
    form.change(name, date && +moment(date));
  }

  const disabledDate = (current) => {
    // Can not select days after today and today
    return current && current > moment().endOf('day');
  }

  const selectRef = useRef(null);

  const handleGlobalClick = e => {
    if (!selectRef.current.contains(e.target)) {
      setTouched(true);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', handleGlobalClick);
    return () => document.body.removeEventListener('click', handleGlobalClick);
  }, []);

  return (
    <div className={classes}  ref={selectRef}>
      {label && (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      )}
      <DatePicker
        className={css.mainDatePicker}
        defaultValue={getInitialValue()}
        onChange={handleChange}
        format={dateFormat}
        placeholder={placeholder}
        disabledDate={disabledDate}

      />
       <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
}

const FieldBirthdayInput = props => {
  return <Field component={FieldAntDatePickerInputComponent} {...props} type="hidden"/>;
};

export default FieldBirthdayInput;
