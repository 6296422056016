import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYoutube.module.css';

const IconSocialMediaYoutube = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="30"
      fill="none"
      viewBox="0 0 42 30"
      className={classes}
    >
      <path        
        d="M41.15 5.249a5.157 5.157 0 00-3.635-3.62C34.287.75 21.375.75 21.375.75s-12.912 0-16.14.846C3.502 2.069 2.075 3.49 1.6 5.249.75 8.462.75 15.125.75 15.125s0 6.697.85 9.877a5.157 5.157 0 003.635 3.619c3.262.879 16.14.879 16.14.879s12.912 0 16.14-.846a5.157 5.157 0 003.635-3.619c.85-3.213.85-9.876.85-9.876s.034-6.697-.85-9.91z"
      ></path>
      <path fill="#fff" d="M28 15.125L17.265 8.969V21.28L28 15.125z"></path>
    </svg>
  );
}


IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
