import React from 'react';
import classNames from 'classnames';

import css from './IconRemove.module.css';

const IconRemove = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.5 0C2.91596 0 0 2.91596 0 6.5C0 10.084 2.91596 13 6.5 13C10.084 13 13 10.084 13 6.5C13 2.91596 10.084 0 6.5 0ZM9.10571 7.95679C9.4231 8.27397 9.4231 8.78853 9.10571 9.10571C8.94722 9.2644 8.73915 9.34375 8.53125 9.34375C8.32335 9.34375 8.11528 9.2644 7.95679 9.10571L6.5 7.64893L5.04321 9.10571C4.88472 9.2644 4.67665 9.34375 4.46875 9.34375C4.26085 9.34375 4.05278 9.2644 3.89429 9.10571C3.5769 8.78853 3.5769 8.27397 3.89429 7.95679L5.35107 6.5L3.89429 5.04321C3.5769 4.72603 3.5769 4.21147 3.89429 3.89429C4.21126 3.5769 4.72624 3.5769 5.04321 3.89429L6.5 5.35107L7.95679 3.89429C8.27376 3.5769 8.78874 3.5769 9.10571 3.89429C9.4231 4.21147 9.4231 4.72603 9.10571 5.04321L7.64893 6.5L9.10571 7.95679Z" fill="#EC563B"/>
    </svg>
  );
};

export default IconRemove;
