import React from 'react';
import classNames from 'classnames';

import css from './IconDownload.module.css';

const IconDownload = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2923_10296)">
        <path d="M6.99952 0C6.72809 0 6.50809 0.22 6.50809 0.491429V9.95429L4.82809 8.27429C4.63666 8.08286 4.32523 8.08286 4.13381 8.27429C3.94238 8.46571 3.94238 8.77714 4.13381 8.96857L6.65095 11.4857C6.74523 11.58 6.87095 11.6286 6.99666 11.6286C7.12238 11.6286 7.24809 11.58 7.34238 11.4857L9.85952 8.96857C10.0509 8.77714 10.0509 8.46571 9.85952 8.27429C9.66809 8.08286 9.35666 8.08286 9.16523 8.27429L7.48523 9.95429V0.488571C7.48809 0.217143 7.27095 0 6.99952 0Z" fill="#FFFFFF"/>
        <path d="M13.2026 13.5119V10.6633C13.2026 10.3919 12.9826 10.1719 12.7112 10.1719C12.4397 10.1719 12.2197 10.3919 12.2197 10.6633V13.0233H1.77688V10.6633C1.77688 10.3919 1.55687 10.1719 1.2883 10.1719C1.01973 10.1719 0.796875 10.3919 0.796875 10.6633V13.5119C0.796875 13.7833 1.01687 14.0004 1.2883 14.0004H12.7112C12.9826 14.0004 13.2026 13.7833 13.2026 13.5119Z" fill="#FFFFFF"/>
      </g>
      <defs>
        <clipPath id="clip0_2923_10296">
        <rect width="14" height="14" fill="#FFFFFF"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDownload;
