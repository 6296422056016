import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { BookingBreakdown, FieldCheckbox, Form } from '../../components';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    inbox,
    showSaleButtons,
    intl,
    handleChange,
    handleReleaseShow,
    handleAccidentShow,
    agreeTerms
  } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const releaseAndAccidentLabel = intl.formatMessage(
    { id: 'TransactionPanel.releaseAndAccidentLabel' },
    {
      releaseLink: <span onClick={() => handleReleaseShow(true)}>Ambassador Release</span>,
      accidentLink: <span onClick={() => handleAccidentShow(true)}>Accident Waiver Form</span>,
    }
  );
  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
        inbox
      />
      {showSaleButtons ? (
        <div className={css.checkboxWrapper}>
          <FinalForm
            onSubmit={() => null}           
            render={formRenderProps => {
              return (
                <Form>
                  <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }}/>
                  <FieldCheckbox
                    id="agreeCheckbox"
                    name="agreeCheckbox"
                    label={releaseAndAccidentLabel}
                    value="agreeCheckbox"
                    className={classNames(css.agreeCheckbox, {[css.checked]: agreeTerms})}
                    key="agreeCheckbox"
                    textClassName={css.agreeCheckboxLabel}
                  />
                </Form>
              );
            }}
          />
        </div>
      ) : null}
    </div>
  ) : null;
};

export default injectIntl(BreakdownMaybe);
