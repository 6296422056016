import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        {/* <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2> */}

        {currentUser ? (
          <NamedLink
            name="SearchPage"
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButtonSearch" />
          </NamedLink>

        ) : (
          <NamedLink
            name="SignupPage"
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButtonLogin" />
          </NamedLink>
        )}

      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
