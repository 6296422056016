import React from 'react';
import { instanceOf, string } from 'prop-types';
import classNames from 'classnames';
import { isSameDay, formatDateToText } from '../../util/dates';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { DATE_TYPE_DATE, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import { tipsWithTimeZone, formatTimeWithTimeZone } from '../../util/dates';

import css from './TimeRange.module.css';

export const TimeRangeComponent = props => {
  const { rootClassName, className, startDate, endDate, dateType, timeZone, intl, isAdminModal } = props;
  const isSingleDay = isSameDay(startDate, endDate, timeZone);

  const adminModalStyles = isAdminModal && css.adminModal
  const adminModalTimeZoneTipStyles = isAdminModal && css.adminModalTimeZoneTip

  const classes = classNames(rootClassName || css.root, adminModalStyles, className);

  if (isSingleDay && dateType === DATE_TYPE_DATE) {
    return (
      <div className={classes}>
         {timeZone && <span className={classNames(css.timeZoneTip, adminModalTimeZoneTipStyles)}>{tipsWithTimeZone(timeZone)}</span>}
        <span className={css.dateSection}>{`${formatTimeWithTimeZone(endDate, timeZone, 'MMM D')}`}</span>
      </div>
    );
  } else if (dateType === DATE_TYPE_DATE) {
    return (
      <div className={classes}>
         {timeZone && <span className={classNames(css.timeZoneTip, adminModalTimeZoneTipStyles)}>{tipsWithTimeZone(timeZone)}</span>}
        <span className={css.dateSection}>{`${formatTimeWithTimeZone(startDate, timeZone, 'MMM D')} -`}</span>
        <span className={css.dateSection}>{`${formatTimeWithTimeZone(endDate, timeZone, 'MMM D')}`}</span>
      </div>
    );
  } else if (isSingleDay && dateType === DATE_TYPE_DATETIME) {
    return (
      <div className={classes}>
         {timeZone && <span className={classNames(css.timeZoneTip, adminModalTimeZoneTipStyles)}>{tipsWithTimeZone(timeZone)}</span>}
         <span className={css.dateSection}>{`${formatTimeWithTimeZone(endDate, timeZone, 'MMM D')}, ${formatTimeWithTimeZone(startDate, timeZone, 'hh:mm A')} - ${formatTimeWithTimeZone(endDate, timeZone, 'hh:mm A')}`}</span>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        {timeZone && <span className={classNames(css.timeZoneTip, adminModalTimeZoneTipStyles)}>{tipsWithTimeZone(timeZone)}</span>}
        <span className={css.dateSection}>{`${formatTimeWithTimeZone(startDate, timeZone, 'MMM D, hh:mm A')} - `}</span>
        <span className={css.dateSection}>{`${formatTimeWithTimeZone(endDate, timeZone, 'MMM D, hh:mm A')}`}</span>
      </div>
    );
  }
};

TimeRangeComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
};

TimeRangeComponent.propTypes = {
  rootClassName: string,
  className: string,
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
  dateType: propTypes.dateType,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(TimeRangeComponent);
