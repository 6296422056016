import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionHowItWorks.module.css';
import ambassadorImage from './ambassador.jpg';
import bookingImage from './booking.jpg';

const SectionHowItWorks = props => {
  const { rootClassName, className, currentUser } = props;
  const [switcher, setSwitcher] = useState(false);


  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
      </div>
      <div className={css.stepsWrapper}>
        <div className={css.roleTitle}>
          <div
          onClick={()=>setSwitcher(false)}
            className={classNames(css.role, {
              [css.activeRole]: !switcher,
            })}
          >
            <FormattedMessage id="SectionHowItWorks.ambassadors" />
          </div>
          <div
           onClick={()=>setSwitcher(true)}
            className={classNames(css.role, {
              [css.activeRole]: switcher,
            })}
          >
            <FormattedMessage id="SectionHowItWorks.booking" />
          </div>
        </div>
        {!switcher ? (
          <div className={css.steps}>
            <div className={css.stepsText}>
              <p className={css.preStep}>
                <FormattedMessage id="SectionHowItWorks.ifYouWant" />
              </p>
              <div className={css.step}>
                <h2 className={css.stepSubTitle}>
                  <span className={css.stepNumber}>1</span>
                  <FormattedMessage id="SectionHowItWorks.signUp" />
                </h2>
              </div>

              <div className={css.step}>
                <h2 className={css.stepSubTitle}>
                  <span className={css.stepNumber}>2</span>
                  <FormattedMessage id="SectionHowItWorks.create" />
                </h2>
              </div>

              <div className={css.step}>
                <h2 className={css.stepSubTitle}>
                  <span className={css.stepNumber}>3</span>
                  <FormattedMessage id="SectionHowItWorks.choose" />
                </h2>
              </div>

              {!currentUser && (
                <div className={css.createListingLink}>
                  <NamedLink name="SignupPage" className={css.button}>
                    <FormattedMessage id="SectionHowItWorks.signUpButton" />
                  </NamedLink>
                </div>
              )}
            </div>

            <div className={css.stepsImg}>
              <img src={ambassadorImage} alt="How Brand Gig Works" />
            </div>
          </div>
        ) : (
          <div className={css.steps}>
            <div className={css.stepsText}>
              <div className={css.step}>
                <h2 className={css.stepTitle}>
                  <span className={css.stepNumber}>1</span>
                  <FormattedMessage id="SectionHowItWorks.part1Title" />
                </h2>
                <p className={css.stepDescription}>
                  <FormattedMessage id="SectionHowItWorks.part1Text" />
                </p>
              </div>

              <div className={css.step}>
                <h2 className={css.stepTitle}>
                  <span className={css.stepNumber}>2</span>
                  <FormattedMessage id="SectionHowItWorks.part2Title" />
                </h2>
                <p className={css.stepDescription}>
                  <FormattedMessage id="SectionHowItWorks.part2Text" />
                </p>
              </div>

              <div className={css.step}>
                <h2 className={css.stepTitle}>
                  <span className={css.stepNumber}>3</span>
                  <FormattedMessage id="SectionHowItWorks.part3Title" />
                </h2>
                <p className={css.stepDescription}>
                  <FormattedMessage id="SectionHowItWorks.part3Text" />
                </p>
              </div>

              {!currentUser && (
                <div className={css.createListingLink}>
                  <NamedLink name="SearchPage" className={css.button}>
                    <FormattedMessage id="SectionHowItWorks.createListingLink" />
                  </NamedLink>
                </div>
              )}
            </div>

            <div className={css.stepsImg}>
              <img src={bookingImage} alt="How Brand Gig Works" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
