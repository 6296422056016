import React from 'react';
import PropTypes from 'prop-types';

import css from './IconShoppingCart.module.css';

const IconShoppingCart = props => {
  const { className } = props;
  return (
    <svg
      className={css.root}
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="40"
      fill="none"
      viewBox="0 0 42 40"
    >
      <path
        fillRule="evenodd"
        stroke="#4D6479"
        strokeWidth="2"
        d="M21.022 39.048c10.555 0 19.111-8.528 19.111-19.048 0-10.52-8.556-19.048-19.11-19.048C10.466.952 1.91 9.48 1.91 20c0 10.52 8.556 19.048 19.111 19.048z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#4D6479"
        d="M28.841 25.931h-13.92c-.23 0-.413-.091-.505-.274a.56.56 0 01-.046-.594l1.195-3.016-1.195-11.244h-3.859v-1.28h4.41c.322 0 .598.23.644.55L16.85 22c0 .091 0 .183-.046.32l-.919 2.285h12.955v1.325z"
      ></path>
      <path
        fill="#4D6479"
        d="M16.345 22.778l-.275-1.234 14.655-3.017v-5.164h-15.16v-1.28h15.803c.367 0 .643.274.643.64v6.307c0 .32-.23.549-.505.594l-15.16 3.154zM29.485 29.725c-1.378 0-2.527-1.143-2.527-2.514 0-1.371 1.149-2.514 2.527-2.514 1.378 0 2.526 1.143 2.526 2.514 0 1.371-1.148 2.514-2.526 2.514zm0-3.794a1.28 1.28 0 00-1.287 1.28c0 .731.552 1.28 1.287 1.28.735 0 1.286-.549 1.286-1.28a1.28 1.28 0 00-1.286-1.28zM14.324 29.725c-1.378 0-2.527-1.143-2.527-2.514 0-1.371 1.149-2.514 2.527-2.514 1.378 0 2.527 1.143 2.527 2.514 0 1.371-1.149 2.514-2.527 2.514zm0-3.794a1.28 1.28 0 00-1.286 1.28c0 .731.551 1.28 1.286 1.28s1.286-.549 1.286-1.28a1.28 1.28 0 00-1.286-1.28z"
      ></path>
    </svg>
  );
};

IconShoppingCart.defaultProps = { className: null };

const { string } = PropTypes;

IconShoppingCart.propTypes = { className: string };

export default IconShoppingCart;
