import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconList.module.css';

const IconList = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.7733 3.11475L8.16904 0.179178C8.06595 0.0515443 7.93832 0 7.78614 0H1.60819C1.32838 0 1.09766 0.230722 1.09766 0.510534V11.4895C1.09766 11.7693 1.32838 12 1.60819 12H10.3928C10.6726 12 10.8788 11.7693 10.9033 11.514V3.44856C10.9009 3.32092 10.8518 3.21784 10.7733 3.11475ZM8.27213 1.8065L9.27111 2.93802H8.27213V1.8065ZM9.87982 11.0059H2.11872V0.996523H7.27561V3.42156C7.27561 3.70137 7.50633 3.93209 7.78614 3.93209H9.87982V11.0059Z" fill="#FFFFFF"/>
      <path d="M3.34375 5.7703C3.34375 6.05011 3.57447 6.28084 3.85428 6.28084H8.14473C8.42454 6.28084 8.65527 6.05011 8.65527 5.7703C8.65527 5.49049 8.42454 5.25977 8.14473 5.25977H3.85428C3.57447 5.25977 3.34375 5.49049 3.34375 5.7703Z" fill="#FFFFFF"/>
      <path d="M8.14473 8.42578H3.85428C3.57447 8.42578 3.34375 8.6565 3.34375 8.93631C3.34375 9.21613 3.57447 9.44685 3.85428 9.44685H8.14473C8.42454 9.44685 8.65527 9.21613 8.65527 8.93631C8.65527 8.6565 8.42454 8.42578 8.14473 8.42578Z" fill="#FFFFFF"/>
    </svg>
  );
};

export default IconList;
